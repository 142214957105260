<template>
  <v-expansion-panel
    v-if="!isNew"
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('booking.formFields.title', locale)}}</v-expansion-panel-header>
    <v-expansion-panel-content
      class="form-fields-list-main"
    >a
      <v-alert
        v-if="!allowReservations"
        outlined
        type="warning"
        text
        style="margin: 0 20px;"
      >
        {{$t('booking.formFieldsAllowReservationsMessage', locale)}}
      </v-alert>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
    <form-fields
      v-if="!loading"
      :locale="locale"
      :onChange="handleChangeIsModified"
      :formFields="formFields"
    />
      <form-field-item
        :editedFormField="editedFormField"
        :isOwner="isFormFieldItemOwner"
        :ownerFieldsUsed="formFields.owner"
        :onClose="handleCloseFormFieldItem"
        :locale="locale"
        :dialog="showFormFieldItem"
      />
      <v-layout wrap style="padding-top: 20px">
        <v-flex xs10 style="padding: 10px 0">
          <h2>{{ $t('booking.formFields.owner', locale) }}</h2>
        </v-flex>
        <v-flex xs2 style="padding: 10px 0">
          <v-btn 
            color="primary"
            block
            @click="handleAddField(true)"
          >
            {{ $t('booking.formFields.add', locale) }}
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <form-fields-table 
            :formFields="formFields.owner"
            :locale="locale"
            :onEdit="handleEdit"
            :onDelete="handleDelete"
            :isOwner="true"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap style="margin-top: 20px">
        <v-flex xs10 style="padding: 10px 0">
          <h2>{{ $t('booking.formFields.pax', locale) }}</h2>
        </v-flex>
        <v-flex xs2 style="padding: 10px 0">
          <v-btn 
            color="primary"
            block
            @click="handleAddField(false)"
          >
            {{ $t('booking.formFields.add', locale) }}
          </v-btn>
        </v-flex>
        <v-flex xs12>
          <form-fields-table 
            :formFields="formFields.pax"
            :locale="locale"
            :onEdit="handleEdit"
            :onDelete="handleDelete"
            :isOwner="false"
          />
        </v-flex>
      </v-layout>
      <!--v-layout wrap>
        <v-flex xs6
          v-for="item of defaultFormFields"
          :key="item.key"
        >
          <v-checkbox
            v-model="ownerFormFields"
            :value="item.key"
            :label="$t(`booking.formFields.${item.key}`, locale)"
            hide-details
            @change="handleChangeOwnerFormFields"
          />
        </v-flex>
      </v-layout>
      <div v-if="formFields">
        <v-btn
          color="primary"
          block
          @click="handleAddSchedule"
        >
          {{ $t('booking.schedules.addSchedule', locale) }}
        </v-btn>
        <schedule-form
          :activityID="editedActivityID"
          :locale="locale" 
          :editedScheduleID="editedScheduleID"
          :onClose="handleCloseScheduleForm"
        />
        <custom-table
          style="margin-top: 40px"
          :loading="loading"
          :headers="[
            { text: $t('workspace.name', locale), value: 'ident' },
            { text: '', value: 'actions', align: 'end' },
          ]"
          :onGetData="handleGetData"
          :tableData="schedules"
          :onEditItem="handleEditSchedule"
          :startIndex="startIndex"
          :itemsPerPage="itemsPerPage"
          :count="count"
          hideHeader
        />
      </div-->
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>


import api from '@/services/api'
import { defaultFormFields } from '@/constants/booking'
import FormFields from '@/components/formFields/Index'
import FormFieldsTable from '@/components/formFields/FormFieldsTable'
import FormFieldItem from '@/components/formFields/FormFieldItem'
//import ScheduleForm from './scheduleForm/Index'
//import CustomTable from '@/components/customTable/Index'
export default {
  components: {
    FormFields,
    FormFieldsTable,
    FormFieldItem,
  //  ScheduleForm,
  //  CustomTable,
  },
  props: {
    locale: {
      type: String,
      default: null,
    },
    editedActivityID: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    allowReservations: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFormFieldItemOwner: false,
    showFormFieldItem: false,
    editedFormField: null,
    ownerFormFields: [],
    formFields: {
      owner: [],
      pax: [],
    },
    schedules: null,
    count: 0,
    startIndex: 0,
    itemsPerPage: 5,
    search: '',
    loading: false,
    editedScheduleID: null,
  }),
  computed: {
    isNew () {
      return this.editedActivityID === 'new'
    },
    defaultFormFields () {
      return defaultFormFields
    },
  },
  watch: {
    editedActivityID () {
      this.formFields = {
        owner: [],
        pax: [],
      }
      this.handleGetData()
    },
  },
  methods: {
    handleExpand () {
      if (this.editedActivityID) this.handleGetData()
    },
    handleGetData () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/activities/${this.editedActivityID}/form-fields`)
        .then(response => {
          const aux = response.length > 0 ? JSON.parse(response[0].FormFields) : null
          if (aux)

          this.formFields.owner = !aux || !aux.owner ? [] : aux.owner
          this.formFields.pax = !aux || !aux.pax ? [] : aux.pax
          this.ownerFormFields = !aux || !aux.owner ? [] : aux.owner.map(item => item.key)
          this.loading = false
        })
    },
    handleAddField (isOwner) {
      this.isFormFieldItemOwner = isOwner
      this.editedFormField = {
        id: 'new',
        activityID: this.editedActivityID,
        required: true,
      }
      if (!isOwner) {
        this.editedFormField.type = null
        this.editedFormField.key = null
        this.editedFormField.label = { es: '' }
      }
      this.showFormFieldItem = true
    },
    handleEdit (v, isOwner) {
      this.isFormFieldItemOwner = isOwner
      v.LMD = new Date().toISOString()
      v.activityID = this.editedActivityID
      this.editedFormField = v
      this.showFormFieldItem = true
    },
    handleDelete (v) {
      this.loading = true
      api.deleteItem ('booking', `v1/private/activities/${this.editedActivityID}/form-fields/`, v)
        .then(() => {
          this.loading = false
          this.handleGetData()
        })

    },
    handleCloseFormFieldItem () {
      this.handleGetData()
      this.showFormFieldItem = false
    },
    /*
    handleChangeOwnerFormFields (v) {
      this.formFields.owner = []
      for (let item of v) {
        const aux = defaultFormFields.filter(x => x.key === item).shift()
        if (aux) this.formFields.owner.push(aux)
      }
      console.log(this.formFields.owner)
      this.onChange({
        activityID: this.editedActivityID,
        formFields: this.formFields,
      })
    },
    handleCloseScheduleForm () {
      this.handleGetData(0)
      this.editedScheduleID = null
    },
    handleAddSchedule () {
      this.editedScheduleID = 'new'
    },
    handleEditSchedule (v) {
      this.editedScheduleID = v
    },*/
  },
}
</script>
<style scoped>
.form-fields-list-main {
  padding-top: 15px;
  min-height: 70px;
}
</style>

